class Ticket {
  //ticket properties or event properties
  ticketPrice = null;
  isTaxable = null;
  isTaxExclusive = null;
  taxPercentage = null;
  isPgExclusive = null;
  pgPercentage = null;
  discountType = null;
  taxPercentageOfPgCharges = null;
  basePgPercentage = null;
  isTaxOnPgInclusive = null;
  discount = 0;
  // resultant values
  groupDiscount = 0
  subTotal = 0.0;
  basePrice = 0.0;
  taxAmount = 0.0;
  pgCharges = 0.0;
  taxOnPgCharges = 0.0;
  khFee = 0.0;
  payableAmount = 0.0;
  settlementToOrganizer = 0.0;
  taxPercentageOfPgCharges = 0.0;

  constructor(
    ticketPrice,
    isTaxable, // false
    isTaxExclusive, // false
    taxPercentage, // 18
    isPgExclusive, // true
    pgPercentage, // 4
    discount,
    groupDiscount,
    isTaxOnPgInclusive,
    taxPercentageOnPg, // 18
    basePgPercentage
  ) {
    this.ticketPrice = ticketPrice;
    this.isTaxable = isTaxable;
    this.ticketPrice = ticketPrice;
    this.isTaxable = isTaxable; // false - is_taxable
    this.isTaxExclusive = isTaxExclusive; // false, not - is_tax_inclusive
    this.taxPercentage = taxPercentage / 100; // .18
    this.isPgExclusive = isPgExclusive; // true, not - is_pg_charge_inclusive
    this.pgPercentage = pgPercentage / 100; // .04
    this.discount = discount;
    this.groupDiscount = groupDiscount
    this.taxPercentageOfPgCharges = taxPercentageOnPg / 100; // change from 0.18;
    this.basePgPercentage = basePgPercentage / 100;
    this.isTaxOnPgInclusive = isTaxOnPgInclusive;
  }

  /**
   *
   * method to calculate the price of the ticket with the discount provided\n
   * - sub_total = ticket_price - discount (amount)
   * Eg:
   *  - ticket_price = 100
   *  - discount = 50
   *  ---> sub_total => ticket_price - discount => 50;
   *     only if discount < ticket_price,
   *      or else,
   *       sub_total = 0
   * @returns amount of the ticket after subtracting the discount price
   *
   * */
  calculateSubTotal() {
    let result = 0;
    if (this.discount) {
      if (this.discount <= this.ticketPrice) {
        result = this.ticketPrice - this.discount;
      } else result = 0;
    }else if(this.groupDiscount && !this.discount) {
          if(this.groupDiscount <= this.ticketPrice) {
            result = this.ticketPrice - this.groupDiscount;
          } else result = 0;
      } else {
      result = this.ticketPrice;
    }
    this.subTotal = result;
    return;
  }

//   if(this.groupDiscount) {
//     if(this.groupDiscount <= this.ticketPrice) {
//       result = this.ticketPrice - this.groupDiscount;
//     } else result = 0;
// }
  /**
   *
   * Method to calculate the base price of the ticket, based on the tax and pg charges.
   * This base price will be the actual price of the ticket after removing the tax and/or pg charges
   *     from the ticket price.
   * Eg:-
   * - ticket_price = 100
   * - discount=0
   * - Sub_total = 100 (We will be calculating the base price after removing the disc amount)
   * - pg_percentage = 0.04
   * - tax_percentage_of_pg_charges= 0.18
   * - tax_percentage = 0.18
   * -> When Tax exclusive and PG exclusive, or,
   *    when Tax is None (is_taxable is False), and PG is exclusive.
   *    (Tax on PG exclusive / inclusive)
   *    --> base_price = sub_total = 100
   * -> When Tax exclusive and PG inclusive, or,
   *    when Tax is none (is taxable is False), and PG is inclusive.
   *    Only when Tax on pg exclusive
   *    --> base_price = sub_total / (
   *         1 + (pg_percentage * (1 + tax_percentage_of_pg_charges))) =
   *             100/(1+(0.04 * 1.18)) = 95.4927425515661
   *    -> When Tax exclusive and PG inclusive, or,
   *    when Tax is none (is taxable is False), and PG is inclusive.
   *    Only when Tax on pg inclusive
   *    --> base_price = sub_total / (
   *         1 + (base_pg_percentage * (1 + tax_percentage_of_pg_charges))) =
   *             100/(1+(0.03 * 1.18)) = 96.24
   * -> When Tax inclusive and PG exclusive.
   *    (Tax on PG exclusive / inclusive)
   *    --> base_price = sub_total / (1 + tax_percentage) = 100/(1.18) = 84.74576271186442
   * -> When Tax inclusive and PG inclusive.
   *    Only when Tax on pg exclusive
   *    --> base_price = (sub_total / (1 + tax_percentage)) / (
   *         1 + pg_percentage * (1 + tax_percentage_of_pg_charges)
   *     ) = (100/(1.18))/(1+(0.04 * 1.18)) = 80.92605300980178
   *
   *    -> When Tax inclusive and PG inclusive.
   *    Only when Tax on pg inclusive
   *    --> base_price = (sub_total / (1 + tax_percentage)) / (
   *         1 + pg_percentage * (1 + tax_percentage_of_pg_charges)
   *     ) = (100/(1.18))/(1+(0.03 * 1.18)) = 81.486
   *
   * */
  calculateBasePrice() {
    //  When Tax exclusive and PG exclusive. (Tax on PG exclusive / inclusive)
    //  When Tax is null (is_taxable is False), and PG is exclusive.
    //
    if (
      (this.isTaxExclusive === true && this.isPgExclusive === true) ||
      (this.isPgExclusive === true && this.isTaxable === false)
    ) {
      this.basePrice = this.subTotal;
      return;
    }
    //  # When Tax exclusive and PG inclusive.
    // # When Tax is none (is taxable is False), and PG is inclusive.
    // Only when Tax on pg exclusive
    if (
      (this.isTaxExclusive === true &&
        this.isPgExclusive === false &&
        this.isTaxOnPgInclusive === false) ||
      (this.isPgExclusive === false &&
        this.isTaxable === false &&
        this.isTaxOnPgInclusive === false)
    ) {
      this.basePrice =
        this.subTotal /
        (1 + this.pgPercentage * (1 + this.taxPercentageOfPgCharges));
      return;
    }
    //  # When Tax exclusive and PG inclusive. (Tax on PG exclusive / inclusive)
    // # When Tax is none (is taxable is False), and PG is inclusive.
    // Only when Tax on pg inclusive
    if (
      (this.isTaxExclusive === true &&
        this.isPgExclusive === false &&
        this.isTaxOnPgInclusive === true) ||
      (this.isPgExclusive === false &&
        this.isTaxable === false &&
        this.isTaxOnPgInclusive === true)
    ) {
      this.basePrice =
        this.subTotal /
        (1 + this.basePgPercentage * (1 + this.taxPercentageOfPgCharges));
      return;
    }

    // # When Tax inclusive and PG exclusive.
    // Only when Tax on pg exclusive
    if (this.isTaxExclusive === false && this.isPgExclusive === true) {
      this.basePrice = this.subTotal / (1 + this.taxPercentage);
      return;
    }

    // When Tax inclusive and PG inclusive.
    //  Only when Tax on pg exclusive
    if (
      this.isTaxExclusive === false &&
      this.isPgExclusive === false &&
      this.isTaxOnPgInclusive === false
    ) {
      this.basePrice =
        this.subTotal /
        (1 + this.taxPercentage) /
        (1 + this.pgPercentage * (1 + this.taxPercentageOfPgCharges));
      return;
    }

    // When Tax inclusive and PG inclusive.
    //  Only when Tax on pg inclusive
    if (
      this.isTaxExclusive === false &&
      this.isPgExclusive === false &&
      this.isTaxOnPgInclusive === true
    ) {
      this.basePrice =
        this.subTotal /
        (1 + this.taxPercentage) /
        (1 + this.basePgPercentage * (1 + this.taxPercentageOfPgCharges));
      return;
    }
  }
  /**
   *
   * method to calculate the tax for the ticket
   * - tax_amount = base_price * tax%
   * Eg:
   * if tax is collected:\n
   * - base_price = 50,
   * - tax_percentage = 18%
   *   --->tax_amount = base_price * tax_percentage => 9.0
   * if tax is not collected:
   *   --->tax_amount = 0
   * @returns tax charged on the ticket
   * */
  calculateTaxAmount() {
    // Tax can be either exclusive or inclusive
    if (this.isTaxable) {
      this.taxAmount = this.basePrice * this.taxPercentage;
      return;
    } else {
      this.taxAmount = 0.0;
      return;
    }
  }
  /**
   *
   * method to calculate the KonfHub amount = (base_price + tax)*PG%
   * Eg:
   *  - base_price = 100
   *  - tax = 18
   *  - PG% = 4
   *  ---> calculate_pg_charges = (base_price + tax) * pg_perc =4.72
   * @returns PG charged on the base_price of the ticket
   *
   * */
  calculatePgCharges() {
    if (!this.isTaxOnPgInclusive) {
      this.pgCharges = (this.basePrice + this.taxAmount) * this.pgPercentage;
      return;
    } else {
      this.pgCharges =
        (this.basePrice + this.taxAmount) * this.basePgPercentage;
      return;
    }
  }

  /**
   *
   * method to calculate the tax on the pg charges\n
   * - tax_on_pg_charges = pg_charges(charged by konfhub) * tax%
   * Eg:\n
   * - pg_charges = 4.72
   * - tax_percentage_of_pg_charges = 18%
   * --->  tax_on_pg_charges = pg_charges * tax_percentage_of_pg_charges = 0.85
   * @returns tax charged on the pg charges
   *
   * */
  calculateTaxOnPgCharges() {
    this.taxOnPgCharges = this.pgCharges * this.taxPercentageOfPgCharges;
    return;
  }
  /**
   *
   * method to calculate total konfhub_fee = pg_charges(charged by konfhub) + tax(charged on pg_charges)\n
   * Eg:\n
   * - pg_charges = 4.72
   * - tax_on_pg_charges = 0.85
   * ---> kh_fee =  = pg_charges + tax_on_pg_charges => 5.6
   * @returns Amount total of pg_charges and calculate_tax_on_pg_charges
   *
   * */
  calculateKhFee() {
    this.khFee = this.pgCharges + this.taxOnPgCharges;
    return;
  }
  /**
   *
   * method to calculate the final amount to be paid by the consumer for the ticket
   *     - payable_amount = base_price + tax_amount(=base_price*tax%)+
   *                         kh_fee(=pg_charges+tax_on_pg_charges%)
   * Eg:\n
   * - base_price=100
   * - tax_amount=18
   * - kh_fee =5.6
   * ---> payable_amount=base_price + tax_amount + kh_fee = 123
   * @returns amount calculated for the ticket(including taxes)
   *
   * */
  calculatePayableAmount() {
    this.payableAmount = this.basePrice + this.taxAmount + this.khFee;
    return;
  }

  /**
   *
   * Final payout amounts for the consumer and settlements to organizer
   * including refunds(if any)
   * :return: a dictionary that will contain the
   *         discount, sub_total, base_price, tax_amount, pg_charges, tax_on_pg_charges, kh_fee,
   *         payable_amount, payable_amount_rounded, settlement_to_organizer,
   *         and their respective values
   * */
  calculate() {
    this.calculateSubTotal();
    this.calculateBasePrice();
    this.calculateTaxAmount();
    this.calculatePgCharges();
    this.calculateTaxOnPgCharges();
    this.calculateKhFee();
    this.calculatePayableAmount();
    const data = {
      discount: this.discount,
      groupDiscount : this.groupDiscount,
      subTotal: this.subTotal,
      basePrice: this.basePrice,
      taxAmount: this.taxAmount,
      pgCharges: this.pgCharges,
      taxOnPgCharges: this.taxOnPgCharges,
      khFee: this.khFee.toFixed(2),
      payableAmount: this.payableAmount.toFixed(2),
      rounded: this.payableAmount.toFixed(2),
    };
    // console.table({
    //     ['Taxable']: this.isTaxable,
    //     ['PG Exclusive']: this.isPgExclusive,
    //     ['Tax Exclusive']: this.isTaxExclusive,
    //     ...data
    // })
    return data;
  }
}

export default Ticket;